.calculator-main-section-box {
  background-color: #4e5477;
  color: white;
  text-align: center;
  padding: 1rem;
  padding-top: 5rem;
  margin-top: 50px;
  width: 100% !important;
}
.calculator-main-section-box .cloudify-header-text-box {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
/* .calculator-main-section-box .cloudify-head-text-box {
  color: #c4c5c9;
  text-align: center;
} */
.calculator-main-section-box .choose-plan-section-box {
  background-color: #05173c;
  margin: 1rem 3rem !important;
  border-radius: 5px;
}
/* new */
.choose-plan-box {
  margin: 1rem 3rem !important;
  border-radius: 5px;
}
.calculator-main-section-box .montly-section-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1.5rem;
}
.calculator-main-section-box .pay-section-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1.5rem;
}
.calculator-main-section-box .selected-box {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: #69d5ab;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.calculator-main-section-box .number-of-days-section-box {
  margin: 0rem 1.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  border-radius: 5px;
  align-items: center;
  padding: 0.5rem 1.5rem;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.calculator-main-section-box .session-execution-box {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.calculator-main-section-box .textField {
  height: 0px !important;
}
.calculator-main-section-box .isoSlider {
  margin-top: 20px !important;
}
.calculator-main-section-box .enter-manually-section-box {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
  font-size: 12px;
}
.calculator-main-section-box .no-box {
  position: relative;
  top: 15px;
  color: #b7b7b7;
  z-index: 1;
}
.calculator-main-section-box .manually-text-box {
  position: relative;
  top: 31px;
  color: #b7b7b7;
  z-index: 1;
  right: 14px;
}
.calculator-main-section-box .total-session-box {
  background-color: #05173c;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.8rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.calculator-main-section-box .total-usage-box {
  background-color: #05173c;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.8rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.calculator-main-section-box .total-session-box span {
  color: #b5b7b9;
}
.calculator-main-section-box .total-usage-box span {
  color: #b5b7b9;
}
.calculator-main-section-box .total-cost {
  text-align: center;
  background-color: #05173c;
  margin-top: 2rem;
  padding: 0.8rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.calculator-main-section-box .divider {
  background-color: #69d5ab;
  margin: 1.5rem;
}
.calculator-main-section-box .count-section {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;
}
.calculator-main-section-box .paragraph {
  color: #b5b7b9;
  font-size: 12px;
  position: relative;
  top: 1px;
}
.calculator-main-section-box .btn-section {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 2rem;
}
.calculator-main-section-box .contact-us {
  background: #232949;
  padding: 0.4rem 3rem;
  border: 0.5px solid #547f87;
  border-radius: 2px;
  cursor: pointer;
}
.calculator-main-section-box .reset {
  background: #232949;
  padding: 0.4rem 4rem;
  border: 0.5px solid #5f667c;
  border-radius: 2px;
  cursor: pointer;
}
.calculator-main-section-box .know-more {
  background: #69d5ab;
  margin-top: 1rem;
  padding: 0.7rem;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  color: black;
}
.calculator-main-section-box .text {
  position: relative;
  top: 7px;
}
.calculator-main-section-box .cost {
  color: #69d5ab;
  font-size: 25px;
}
.calculator-main-section-box .cost span {
  color: white;
  font-size: 30px;
}
.calculator-main-section-box .numberofdays-section {
  display: flex;
}
.numberofdays-section .manual-text {
  color: #c2c2c2;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calculator-main-section-box .checkbox {
  color: #69d5ab !important;
}
.calculator-main-section-box .choose-plan-container-box {
  text-align: center;
}
.calculator-main-section-box .checkbox-before {
  color: gray !important;
}
.calculator-main-section-box {
  text-align: left;
}
.calculator-main-section-box .numberofdays-section input {
  width: 80px;
  margin-left: 10px;
  padding: 0.5rem;
  background: #05173c;
  border: 1px solid #05173c;
  border-radius: 5px;
  color: white;
  text-align: center;
  padding-left: 25px;
}
/* calulator new changes */
.choose-plan-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.textField-calculator > label {
  color: #ffffff !important;
}
.textField-calculator > div > fieldset {
  border: 1px solid #ffffff !important;
}
.textField-calculator > div > input {
  -webkit-text-fill-color: white !important;
}
.per_session {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

@media screen and (max-width: 1127px) {
  .calculator-main-section-box .contact-us {
    padding: 0.4rem 1.5rem;
  }
  .calculator-main-section-box .reset {
    padding: 0.4rem 2.5rem;
  }
}
@media screen and (max-width: 850px) {
  .calculator-main-section-box .text {
    font-size: 15px;
    position: relative;
    top: 5px;
  }
  .choose-plan-box {
    margin: 2rem 0rem !important;
  }
}
@media screen and (max-width: 610px) {
  .calculator-main-section-box .btn-section {
    margin: 0px;
  }
  .calculator-main-section-box .total-session-box {
    gap: 20px;
    font-size: 12px;
  }
  .calculator-main-section-box .total-usage-box {
    gap: 20px;
    font-size: 12px;
  }
  .calculator-main-section-box .btn-section {
    display: flex;
    flex-direction: column;
  }
  .calculator-main-section-box .numberofdays-section input {
    width: 50px;
    padding-left: 10px;
  }
  .calculator-main-section-box .number-of-days-section-box {
    padding: 0.5rem;
  }
  .calculator-main-section-box .choose-plan-section-box {
    margin: 0rem !important;
  }
  .calculator-main-section-box .number-of-days-section-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .calculator-main-section-box .selected-box {
    padding: 0.5rem;
  }
  .calculator-main-section-box .cloudify-header-text-box {
    font-size: 25px;
  }
  .calculator-main-section-box .contact-us {
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .calculator-main-section-box {
    margin-top: 0px;
  }
  .panel-box {
    color: gray !important;
    font-size: 11px !important;
    text-align: left !important;
  }
  /* .calculator-main-section-box .cloudify-head-text-box {
    font-size: 16px;
  } */
}

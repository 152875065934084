.home_container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
  background-color: #14122c;
  padding-left: 6rem;
  padding-right: 6rem;
}
.left_section {
  padding: 2rem;
  display: flex;
  justify-content: center;
  color: white;
}
.home_text {
  font-size: 28px;
  font-weight: 600;
}
hr {
  margin: 1rem 1rem 1rem 0rem;
}
.cloud_text {
  font-family: system-ui;
  font-size: 35px;
  font-weight: 100;
}
.cloud_text > span {
  color: #6ad8b2;
  font-weight: 300;
}
.section_bottom {
  margin-top: 20px;
  font-size: 26px;
}
.section_bottom > div > span {
  color: #6ad8b2;
}
.demo_section {
  display: flex;
}
.demo {
  border: 1px solid white;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 20px;
  margin-top: 50px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.demo:hover {
  color: #6ad8b2;
  border-color: white;
}

.right_section {
  display: flex;
  justify-content: center;
}
.img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1240px) {
  .home_container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media screen and (max-width: 1055px) {
  .img {
    width: 350px;
  }
}

@media screen and (max-width: 977px) {
  .home_container > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 950px) {
  .img {
    width: 300px;
  }
}

@media screen and (max-width: 820px) {
  .home_container {
    flex-direction: column;
    display: flex;
    justify-content: 2rem;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    /* padding-left: 2rem;
    padding-right: 2rem; */
  }

  .home_text {
    font-size: 20px;
    font-weight: 600;
  }
  .cloud_text {
    font-size: 20px;
  }
  .section_bottom {
    font-size: 18px;
  }
  .demo_section {
    display: flex;
    justify-content: center;
  }
  .demo {
    border: 1px solid;
    padding: 1rem 1rem;
    border-radius: 5px;
    font-size: 15px;
  }
}

.right_section {
  display: contents;
}

@media screen and (max-width: 480px) {
  .home_text {
    font-size: 15px;
    font-weight: 600;
    margin-top: 55px;
    margin-left: 1rem;
  }
  hr {
    margin-left: 1rem;
  }
  .cloud_home_text {
    margin-left: 1rem;
  }
  #about .about_section .about_container {
    gap: 0px;
    padding: 0rem 1rem !important;
  }
  .h {
    padding: 0rem;
  }
  .left_section {
    padding: 0px;
    padding-top: 30px;
    justify-content: center;
  }
  .cloud_text {
    font-size: 14px;
  }
  .section_bottom {
    margin-left: 1rem;
  }
  .demo_section {
    display: flex;
    justify-content: center;
  }
  .demo {
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    font-size: 12px;
  }
}
.right_section {
  display: contents;
}

@media screen and (max-width: 380px) {
  .home_container {
    flex-direction: column;
    display: flex;
    justify-content: 2rem;
    justify-content: center;
    align-items: center;
  }
  .img {
    width: 220px;
    position: relative;
    top: 25px;
  }
}

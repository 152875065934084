.nav {
  background-color: #15122c !important;
  box-shadow: none !important;
  padding: 0.5rem 0.5rem !important;
}
.logo_container {
  display: flex;
}
.logo_container > img {
  width: 45px;
}
.logo_container span {
  font-size: 35px;
  color: #69d5ab;
}
.links {
  text-transform: capitalize !important;
  color: white !important;
}
.link-dr {
  color: black;
}
.active {
  color: #6ad8b2 !important;
}
.support_icon {
  display: block !important;
  position: fixed !important;
  bottom: 2rem;
  right: 2rem;
  scale: 2;
  border-radius: 50% !important;
}

.support_icon > a > svg {
  border: 1px solid grey;
  border-radius: 50%;
  padding: 3px;
  scale: 1.5;
}
@media screen and (max-width: 1050px) {
  .logo_container > img {
    width: 40px;
  }
  .logo_container span {
    font-size: 25px;
    color: #69d5ab;
  }
  .support_icon {
    right: 0rem;
    scale: 1.3;
  }
}

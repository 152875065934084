.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100%;
  min-height: 100vh;
  opacity: 1;
  background: transparent;
  color: white;
  border-radius: none;
  box-shadow: none;
  background-image: linear-gradient(
      195deg,
      rgba(66, 66, 74, 0.6),
      rgba(25, 25, 25, 0.6)
    ),
    url(/src/assets/background.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center;
  background-repeat: no-repeat;
}

.img-container {
  width: 50%;
  height: 50%;
}

.img-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
}

h1,
h2 {
  font-family: "PT Sans", sans-serif;
  text-align: center;
}

#about {
  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background: #ecf5ff;
}
.about_section {
  padding-top: 5rem;
  text-align: center;
}
.text_about {
  font-size: 2.5rem;
  line-height: 1.4;
  color: #469272;
  font-weight: 500;
}
.about_container {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  padding: 1rem;
}
.img_about {
  height: 500px;
}
.about_box {
  border-radius: 5px;
  text-align: left;
  font-size: 20px;
}
.about_box_text {
  margin-top: 10px;
  font-size: 20px;
  padding-right: 0.6rem;
}
#about .about_section .about_container .about_box {
  margin-top: 50px;
}

@media screen and (max-width: 1200px) {
  .about_container {
    flex-direction: column;
  }
  .img_about {
    height: 300px;
  }
  .text_about {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  #about {
    margin-top: 0px;
  }
  .text_about {
    padding-top: 15px;
    font-size: 1.7rem;
  }
  .about_section {
    padding-top: 0px;
  }
  .about_box_text {
    font-size: 16px;
    padding-right: 0px;
  }
}
@media screen and (max-width: 380px) {
  #about .about_section .about_container .about_box {
    margin-top: 0px;
  }
  .text_about {
    position: relative;
  }
  .text_about {
    margin: 0rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about_container {
    gap: 0px;
  }
  #about .about_section .about_container {
    gap: 0px;
  }
}

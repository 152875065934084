.usag {
  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background: #ecf5ff;
  margin-top: 50px;
}
.usage_container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
}
.paper {
  background: #ecf5ff !important;
}
.paper p {
  background: #ecf5ff !important;
  font-size: 2.5rem !important;
  line-height: 1.4 !important;
  margin: 0 0 1.5rem 0 !important;
  color: #469272 !important;
  font-weight: 500 !important;
}
.autoplay div div div .step .heading {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
/* .autoplay div div div .step .desc {
  font-style: italic;
  font-size: 20px;
  text-align: justify;
} */

@media screen and (max-width: 480px) {
  .paper p {
    font-size: 1.5rem !important;
  }
  /* .autoplay div div div .step .desc {
    text-align: center;
    font-size: 16px;
  } */
  .autoplay div div div .step .heading {
    font-size: 16px;
    text-align: center;
  }
  .usag .usage_container {
    padding-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .usag {
    margin-top: 0px;
    padding: 0rem;
  }
  .paper p {
    font-size: 22px !important;
    width: 100% !important;
    text-align: center !important;
  }
  .usag .usage_container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
